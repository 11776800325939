export enum PricingCodesCategorySubCategory {
  salesRfp = 'SalesRfp',
  pricing = 'Pricing',
  pricingAnalysis = 'PricingAnalysis',
  pricingApp = 'PricingApp',
  pamType = 'PamType',
  pamStatus = 'PamStatus',
  salesRegion = 'Sales Region',
  area = 'Area',
  security = 'Security',
  salesProgram = 'SalesProgram',
  nmfcClass = 'NMFCClass',
  discType = 'DiscType',
  amcType = 'AmcType',
  rulesTariff = 'RulesTariff',
  fscTariff = 'FscTariff',
  packaging = 'Packaging',
  bidDueReason = 'BidDueReason',
  plType = 'PricingTypeSpec',
  obiProgram = 'ObiProgram',
  laneDiscountTypes = 'LANE_DISCOUNT_TYPES',
}
