import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConstantsService {
  /** App constants go here */
  RFP = 'RFP';
  SAM = 'SAM';
  PAM = 'PAM';
  LIABILITY = 'Liability';
  CANCEL_LETTER = 'CancelLetter';
}
