export enum AppRoutes {
  LANDING_PAGE = 'landing-page',
  ADMIN_TOOL = 'admin-tool',
  PAM_LIST = 'pam-list',
  PAM_ID = 'pam-id',
  MONITOR_ADJUST_PARAMETERS = 'pam-parameters',
  NOT_AUTHORIZED_PAGE = 'not-authorized-page',
  PRICING_MODULE_GROUPS = 'pricing-module-groups',
  PRICING_MODULE_LIBRARY = 'pricing-module-library',
  PRICING_MODULE_GROUP_MANAGEMENT = 'pricing-module-group-management',
  LIABILITY_AGREEMENT_LIST = 'liability-agreement-list',
  CANCEL_AGREEMENTS_LIST = 'cancel-agreements-list',
  RFP_DASHBOARD = 'rfp-dashboard',
  RFP = 'rfp',
  CUSTOMERS = 'customers',
  ADD_LOCATION = 'add-location',
  ADJUST_EXPECTATION = 'adjust-expectation',
}
