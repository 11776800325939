import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { User } from '@xpo-ltl/sdk-common';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppRoutes } from '../../enums/app-routes.enum';
import { LoggedInUserService } from '../../services/logged-in-user/logged-in-user.service';
import { UserAccessService } from '../../services/user-access/user-access.service';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionGuard implements CanActivate, CanLoad {
  user: User;
  constructor(private router: Router, private userAccess: UserAccessService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    /** TODO: see user-role.service.ts on allowed roles... if a user has more than one allowed role,
     *        let them select the role to log in as.
     **/
    return this.isUserAuthorized(route);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.isUserAuthorized(route);
  }

  private isUserAuthorized(route): Observable<boolean> {
    console.log(route);
    const appName = route.path.replace(/-/g, ' ').toUpperCase();
    return this.userAccess.hasUserAccess(appName).pipe(
      tap((hasAccess) => {
        if (!hasAccess) {
          this.router.navigate([AppRoutes.NOT_AUTHORIZED_PAGE]);
        }
      })
    );
  }
}
