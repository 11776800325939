import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core';
import { HyperLinkRendererComponent } from './cell-renderers/hyper-link-renderer/hyper-link-renderer.component';
import {
  ActionBarCenterContent,
  ActionBarComponent,
  ActionBarLeftContent,
  ActionBarRightContent,
} from './components/action-bar/action-bar.component';
import { ViewRefDirective } from './directives/view-ref.directive';
import { PercentPipe } from './pipes/percent/percent.pipe';
import { SortByPipe } from './pipes/sort/sort-by.pipe';

const components = [
  ActionBarComponent,
  ActionBarLeftContent,
  ActionBarCenterContent,
  ActionBarRightContent,
  HyperLinkRendererComponent,
];

const pipes = [PercentPipe, SortByPipe];

const directives = [ViewRefDirective];

@NgModule({
  declarations: [...components, ...pipes, ...directives],
  imports: [
    CommonModule,
    XpoIconModule,
    // Pipes/Directives
  ],
  exports: [...components, ...pipes, ...directives],
  // providers:  MAKE providedIn: 'root' on your services!!!
})
export class SharedModule {}
