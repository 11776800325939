<h1 mat-dialog-title>
  Upload File
  <xpo-dialog-close-button></xpo-dialog-close-button>
</h1>

<div mat-dialog-content class="upload">
  <xpo-upload [config]="attachmentsConfig" multiple="false"></xpo-upload>
  <div class="upload-action-buttons">
    <button
      mat-flat-button
      xpoSmallButton
      [disabled]="!hasFiles || processing"
      (click)="attachmentsConfig.uploadFileTrigger()"
    >
      Upload File
    </button>
  </div>
</div>
