import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { XpoSnackBar, XpoUpload, XpoUploadConfig } from '@xpo-ltl/ngx-ltl-core';
import { throwError } from 'rxjs';
import { SnackbarStatus } from '../../shared/enums/snackbar-status.enum';
import { PricingService } from '../../shared/services/pricing/pricing.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent implements OnInit {
  @ViewChild(XpoUpload, { static: true }) xpoUpload: XpoUpload;

  // We'll toggle this from "Upload with Error" button
  throwError = false;
  processing = false;

  attachmentsConfig: XpoUploadConfig = {
    // We don't want to upload files when dropped in for this example
    uploadFilesOnDrop: false,
    // Once the file upload finishes sending all the content of the file
    onComplete: () => {
      setTimeout(() => {
        this.processing = false;
        this.attachmentsConfig.clearUploaded();
        this.snackbar.success('File Upload Successful');
      }, 1000);
    },
    // When any error happens during the upload
    onError: (_file, err) => {
      this.processing = false;
      const errorMessage = 'File Upload Failed';
      this.snackbar.open({
        message: 'Error',
        detailedMessage: errorMessage,
        status: SnackbarStatus.error,
      });
    },
    // Once user selects the desired file:
    onFilesChange: () => {},
    // Starts the uploading process
    uploadFile: (file: File) => {
      this.processing = true;
      const request = (fileData: File) => {
        return this.pricingService.uploadRfpAttachments(fileData, '61138');
      };
      // If you've set this.throwError to true we'll throw an error for simulation purposes.
      return this.throwError ? throwError('error message') : request(file);
    },
  };

  get hasFiles(): boolean {
    return this.xpoUpload.files.length > 0;
  }
  constructor(private pricingService: PricingService, private snackbar: XpoSnackBar) {}

  ngOnInit() {}
}
