import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';
import { User } from '@xpo-ltl/sdk-common';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';

@Injectable({
  providedIn: 'root',
})
export class LoggedInUserService {
  userSource = new BehaviorSubject<User>(undefined);
  user$ = this.userSource.asObservable();

  get User(): User {
    return this.userSource.value;
  }

  set User(value: User) {
    this.userSource.next(value);
  }

  constructor(private ltlLoggedInUser: XpoLtlLoggedInUserService, private configManagerService: ConfigManagerService) {}

  getUser(): Observable<User> {
    if (this.userSource.value) {
      return of(this.User);
    } else {
      return this.ltlLoggedInUser
        .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
        .pipe(
          map((user: User) => {
            if (user) {
              this.userSource.next(user);
              return user;
            }
          })
        );
    }
  }
}
