import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core';
import { UiComponentsModule } from '../ui-components.module';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { UploadFileComponent } from './upload-file/upload-file.component';

const dialogs = [
  // YOUR DIALOGS
  NotAuthorizedComponent,
  UploadFileComponent,
];

@NgModule({
  declarations: [...dialogs],
  imports: [
    // MATERIAL OR NGX CORE COMPONENTS
    XpoDialogModule,
    MatIconModule,
    UiComponentsModule,
  ],
  exports: [...dialogs],
  entryComponents: [...dialogs],
})
export class DialogModule {}
