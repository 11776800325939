import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { XpoGridFacetFilterModule, XpoGridToolbarModule, XpoNgxGridModule } from '@xpo-ltl/ngx-grid';
import {
  XpoButtonModule,
  XpoCardModule,
  XpoCheckboxModule,
  XpoConfirmDialogModule,
  XpoContextualHeaderModule,
  XpoDialogModule,
  XpoEmptyStateModule,
  XpoFacetFilterModule,
  XpoIconModule,
  XpoPopoverModule,
  XpoSelectModule,
  XpoShellModule,
  XpoSnackBarModule,
  XpoStatusIndicatorModule,
  XpoTabsModule,
  XpoTooltipModule,
  XpoTrayModule,
  XpoUploadModule,
} from '@xpo-ltl/ngx-ltl-core';
import { PercentPipe } from './shared/pipes/percent/percent.pipe';
import { SortByPipe } from './shared/pipes/sort/sort-by.pipe';
import { SharedModule } from './shared/shared.module';

const modules = [
  SharedModule,
  FlexLayoutModule,
  ReactiveFormsModule,
  FormsModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatMomentDateModule,
  MatMenuModule,
  MatTableModule,
  MatCheckboxModule,
  MatButtonModule,
  MatTooltipModule,
  MatRadioModule,
  MatExpansionModule,
  MatAutocompleteModule,
  MatDividerModule,
  // Xpo Components
  XpoButtonModule,
  XpoSelectModule,
  XpoCardModule,
  XpoDialogModule,
  XpoIconModule,
  XpoSnackBarModule,
  XpoTabsModule,
  XpoContextualHeaderModule,
  XpoUploadModule,
  XpoTrayModule,
  XpoEmptyStateModule,
  XpoShellModule,
  XpoStatusIndicatorModule,
  XpoCheckboxModule,
  XpoTooltipModule,
  XpoPopoverModule,
  XpoConfirmDialogModule,
  XpoStatusIndicatorModule,
  XpoNgxGridModule,
  XpoGridToolbarModule,
  XpoGridFacetFilterModule,
  XpoFacetFilterModule,
];

const pipes = [DatePipe, CurrencyPipe, PercentPipe, SortByPipe];

@NgModule({
  declarations: [],
  imports: [CommonModule, ...modules],
  providers: [...pipes],
  exports: [...modules, ...pipes],
})
export class UiComponentsModule {}
