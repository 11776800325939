import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { NotAuthorizedComponent } from './dialogs/not-authorized/not-authorized.component';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { UserPermissionGuard } from './shared/guards/user-permission/user-permission.guard';

const routerDefinitions: Routes = [
  {
    path: AppRoutes.LANDING_PAGE,
    loadChildren: () => import('./sales-landing-page/sales-landing-page.module').then((m) => m.SalesLandingPageModule),
    canLoad: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.RFP_DASHBOARD,
    loadChildren: () => import('./sales-rfp/sales-rfp.module').then((m) => m.SalesRfpModule),
    canLoad: [XpoAuthenticationGuard],
  },
  /*{
    path: AppRoutes.ADMIN_TOOL,
    loadChildren: () => import('./admin-tool/admin-tool.module').then((m) => m.AdminToolModule),
    canLoad: [XpoAuthenticationGuard],
  },*/
  {
    path: AppRoutes.PAM_LIST,
    loadChildren: () => import('./monitor-adjust/monitor-adjust.module').then((m) => m.MonitorAdjustModule),
    canLoad: [XpoAuthenticationGuard, UserPermissionGuard],
  },
  {
    path: AppRoutes.MONITOR_ADJUST_PARAMETERS,
    loadChildren: () =>
      import('./monitor-adjust-parameters/monitor-adjust-parameters.module').then(
        (m) => m.MonitorAdjustParametersModule
      ),
    canLoad: [XpoAuthenticationGuard, UserPermissionGuard],
  },
  {
    path: AppRoutes.NOT_AUTHORIZED_PAGE,
    component: NotAuthorizedComponent,
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.LIABILITY_AGREEMENT_LIST,
    loadChildren: () =>
      import('./liability-agreements/liability-agreements.module').then((m) => m.LiabilityAgreementsModule),
    canLoad: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.CANCEL_AGREEMENTS_LIST,
    loadChildren: () => import('./cancel-agreements/cancel-agreements.module').then((m) => m.CancelAgreementsModule),
    canLoad: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.CUSTOMERS,
    loadChildren: () => import('./customers/customers.module').then((m) => m.CustomersModule),
    canLoad: [XpoAuthenticationGuard],
  },
  // ADD ANY NEW ROUTE / PAGE HERE
  {
    path: '**',
    redirectTo: `/${AppRoutes.LANDING_PAGE}`,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routerDefinitions, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
