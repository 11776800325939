import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { XpoLtlReleaseNotesService } from '@xpo-ltl/ngx-ltl';
import { XpoShellRoute } from '@xpo-ltl/ngx-ltl-core';
import { AccountUrls } from './shared/enums/account-urls.enum';
import { AppName } from './shared/enums/app-name.enum';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  title: string;
  build: string;
  routes: XpoShellRoute[];
  notifications = [{ title: 'Important notification 1', url: 'https://tcts3-app.ltl.xpo.com/appjs/ngx-ltl-core-docs' }];
  readonly AccountUrls = AccountUrls;

  constructor(
    private configManagerService: ConfigManagerService,
    private releaseNotesService: XpoLtlReleaseNotesService,
    private authService: XpoLtlAuthenticationService
  ) {
    this.routes = [
      {
        label: 'Sales Landing Page',
        path: AppRoutes.LANDING_PAGE,
      },
    ];

    /** Shell setup */
    this.title = AppName.Pricing;
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
  }

  private static getProfilePictureUrl(email: string): string {
    return `${AccountUrls.switchApiUrl}${email}${AccountUrls.pictureUrl}`;
  }

  ngOnInit(): void {
    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.authService.initAuthSetup$(region).subscribe();
  }

  handleReleaseNotesClick(): void {
    this.releaseNotesService.showReleaseNotes().subscribe(() => {});
  }

  openRating() {
    window.location.href = 'https://rating-j.dev-ltl-xpo.com/what-if';
  }
}
