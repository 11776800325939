import { Injectable } from '@angular/core';
import { Code, User } from '@xpo-ltl/sdk-common';
import { PricingAdminPersonnel } from '@xpo-ltl/sdk-pricing';
import _ from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, retryWhen, skipWhile, switchMap } from 'rxjs/operators';
import { PricingCodesCategorySubCategory } from '../../enums/pricing-codes-category-sub-category.enum';
import { genericRetryStrategy } from '../../utils/rxjs-utils';
import { LoggedInUserService } from '../logged-in-user/logged-in-user.service';
import { PricingService } from '../pricing/pricing.service';

@Injectable({
  providedIn: 'root',
})
export class UserAccessService {
  userAccess = new BehaviorSubject<Array<string>>(undefined);
  userAccess$ = this.userAccess.asObservable();

  constructor(private pricingService: PricingService, private loggedInUser: LoggedInUserService) {
    this.loggedInUser.user$.pipe(skipWhile((user) => !user)).subscribe((user: User) => {
      this.loadUserAccess(user);
    });
  }

  loadUserAccess(user: User) {
    this.getUserAccessApps(user).subscribe((permissions) => {
      this.userAccess.next(permissions);
    });
  }

  getUserAccessApps(user: User): Observable<Array<string>> {
    if (user) {
      return this.pricingService.getEmployeeDetails(user.employeeId).pipe(
        switchMap((personnel: PricingAdminPersonnel) => {
          const employeeRoleType = personnel.employeeRoleType;
          const additionalRoleType = personnel.additionalRoleType;
          const roles = [];
          if (employeeRoleType && employeeRoleType.trim()) {
            roles.push(employeeRoleType.trim());
          }
          if (additionalRoleType && additionalRoleType.trim()) {
            roles.push(...additionalRoleType.split(',').map((role) => role.trim()));
          }
          return this.pricingService.getPricingCodes().pipe(
            switchMap((response: Code[]) => {
              const userAccess = _.filter(response, (pricingCode) => {
                return (
                  pricingCode.category === PricingCodesCategorySubCategory.pricingApp &&
                  pricingCode.subCategory === PricingCodesCategorySubCategory.security &&
                  pricingCode.value.split(',').some((r) => roles.indexOf(r) >= 0)
                );
              }).map((permission: Code) => permission.name);
              console.log(userAccess);
              return of(userAccess);
            })
          );
        }),
        retryWhen(
          genericRetryStrategy({
            scalingDuration: 2000,
            excludedStatusCodes: [404],
          })
        ),
        catchError((err: any) => {
          console.error(err);
          return of([]);
        })
      );
    }
  }

  hasUserAccess(appName: string): Observable<boolean> {
    console.log(appName);
    if (this.userAccess.value) {
      return of(this.userAccess.value.includes(appName));
    } else {
      console.log(this.loggedInUser.getUser().subscribe((resp) => console.log(resp)));
      return this.loggedInUser.getUser().pipe(
        skipWhile((user) => !user),
        switchMap((user: User) => {
          console.log(user);
          return this.getUserAccessApps(user).pipe(
            map((data) => {
              return data.includes(appName);
            })
          );
        })
      );
    }
  }
}
