import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'app-hyper-link-renderer',
  templateUrl: './hyper-link-renderer.component.html',
  styleUrls: ['./hyper-link-renderer.component.scss'],
})
export class HyperLinkRendererComponent implements AgRendererComponent {
  content: string;
  url: string;

  constructor() {}

  agInit(params: any): void {
    this.refresh(params);
  }

  refresh(params: any): boolean {
    this.content = !!params.valueFormatter ? params.valueFormatter(params.value) : params.value;
    this.url = params.url(params.value);
    return true;
  }
}
