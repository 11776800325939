import { Injectable } from '@angular/core';
import { DataOptions, HttpOptions } from '@xpo-ltl/data-api';
import { Code } from '@xpo-ltl/sdk-common';
import {
  AccessorialService,
  AccountDetail,
  CreatePricingAgreementDocumentRqst,
  CreatePricingAgreementMonitorParameterResp,
  CreatePricingAgreementMonitorParameterRqst,
  CreatePricingCodeResp,
  CreatePricingCodeRqst,
  CreateSalesRfpSupportingDocsQuery,
  CreateSalesRfpSupportingDocsResp,
  CreateSalesRfpSupportingDocsRqst,
  DeletePricingCodePath,
  GetPricingAgreementDocumentPath,
  GetPricingAgreementDocumentQuery,
  GetPricingAgreementMonitorPath,
  GetPricingAgreementMonitorResp,
  GetPricingAnalystDetailsResp,
  GetPricingAnalystDetailsRqst,
  GetSalesRfpPath,
  GetSalesRfpQuery,
  GetSalesRfpResp,
  ListGlobalAccessorialsQuery,
  ListGlobalAccessorialsResp,
  ListPraAgreementMonitorRunDateQuery,
  ListPricingAgreementDocHdrsQuery,
  ListPricingAgreementDocResp,
  ListPricingAgreementMonitorParameterResp,
  ListPricingAgreementMonitorParametersQuery,
  ListPricingAgreementMonitorResp,
  ListPricingAgreementMonitorRunDateResp,
  ListPricingAgreementMonitorsQuery,
  ListPricingAgreementsQuery,
  ListPricingAgreementsResp,
  ListPricingAnalysisWorkflowProgressPath,
  ListPricingAnalysisWorkflowProgressQuery,
  ListPricingAnalysisWorkflowProgressResp,
  ListPricingAnalystAssignmentsQuery,
  ListPricingAnalystAssignmentsResp,
  ListPricingAnalystsQuery,
  ListPricingAnalystsResp,
  ListPricingCodesByTypeQuery,
  ListPricingCodesByTypeResp,
  ListPricingCustomerDetailsQuery,
  ListPricingCustomerDetailsResp,
  ListPricingProposalHeadersQuery,
  ListPricingProposalHeadersResp,
  ListSalesRfpAuditLogsPath,
  ListSalesRfpAuditLogsQuery,
  ListSalesRfpAuditLogsResp,
  ListSalesRfpResp,
  ListSalesRFPsQuery,
  ListSalesRfpSupportingDocsPath,
  ListSalesRfpSupportingDocsQuery,
  ListSalesRfpSupportingDocsResp,
  PricingAdminPersonnel,
  PricingAgreement,
  PricingAgreementMonitor,
  PricingAgreementMonitorParameter,
  PricingAnalysisFlow,
  PricingAnalystAssignment,
  PricingApiService,
  PricingProposalHdr,
  RfpAuditLog,
  SalesRfp,
  SalesRfpContact,
  SalesRfpHdr,
  SalesRfpSupportingDocument,
  UpdateSalesRfpQuery,
  UpdateSalesRfpRqst,
} from '@xpo-ltl/sdk-pricing';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { PricingCodesCategorySubCategory } from '../../enums/pricing-codes-category-sub-category.enum';
import { ConstantsService } from '../constants/constants.service';

@Injectable({
  providedIn: 'root',
})
export class PricingService {
  private pricingCodesCache$: Observable<Code[]>;
  private globalAccessorialsCache$: Observable<AccessorialService[]>;

  constructor(private pricingApi: PricingApiService, private constants: ConstantsService) {}

  getEmployeeDetails(empId: string): Observable<PricingAdminPersonnel> {
    const requestParams = new GetPricingAnalystDetailsRqst();
    requestParams.pricingAnalystEmplId = empId;
    return this.pricingApi.getPricingAnalystDetails(requestParams).pipe(
      map((resp: GetPricingAnalystDetailsResp) => {
        return resp.pricingAnalystDetails;
      })
    );
  }

  getPricingAnalysts(queryParams: ListPricingAnalystsQuery): Observable<PricingAdminPersonnel[]> {
    return this.pricingApi.listPricingAnalysts(queryParams).pipe(
      map((response: ListPricingAnalystsResp) => {
        return response.pricingAnalysts;
      })
    );
  }

  getSalesRfpList(queryParams?: ListSalesRFPsQuery): Observable<SalesRfp[]> {
    return this.pricingApi.listSalesRFPs(queryParams).pipe(
      map((response: ListSalesRfpResp) => {
        return response.salesRfp;
      })
    );
  }

  getSalesRfpBasedOnRfpId(rfpId: string): Observable<SalesRfp> {
    const pathParams = new GetSalesRfpPath(),
      queryParams = new GetSalesRfpQuery();
    queryParams.levelOfDetail = 'complete';
    pathParams.salesRfpId = rfpId;
    return this.pricingApi.getSalesRfp(pathParams, queryParams).pipe(
      map((response: GetSalesRfpResp) => {
        return response.salesRfp;
      })
    );
  }

  getRfpAttachments(rfpId: string): Observable<SalesRfpSupportingDocument[]> {
    const pathParams = new ListSalesRfpSupportingDocsPath(),
      queryParams = new ListSalesRfpSupportingDocsQuery();
    pathParams.salesRfpId = rfpId;
    return this.pricingApi.listSalesRfpSupportingDocs(pathParams, queryParams).pipe(
      map((resp: ListSalesRfpSupportingDocsResp) => {
        return resp.supportingDocs;
      })
    );
  }

  getRfpHistory(rfpId: string): Observable<RfpAuditLog[]> {
    const pathParams = new ListSalesRfpAuditLogsPath(),
      queryParams = new ListSalesRfpAuditLogsQuery();
    pathParams.salesRfpId = rfpId;
    return this.pricingApi.listSalesRfpAuditLogs(pathParams, queryParams).pipe(
      map((resp: ListSalesRfpAuditLogsResp) => {
        return resp.auditLogDetails;
      })
    );
  }

  requestPricingCodes(): Observable<Code[]> {
    const queryParams = new ListPricingCodesByTypeQuery();
    queryParams.categoryCode = [
      PricingCodesCategorySubCategory.pricing,
      PricingCodesCategorySubCategory.pricingAnalysis,
      PricingCodesCategorySubCategory.pricingApp,
      PricingCodesCategorySubCategory.salesRfp,
    ];
    queryParams.subCategoryCode = [
      PricingCodesCategorySubCategory.area,
      PricingCodesCategorySubCategory.bidDueReason,
      PricingCodesCategorySubCategory.pamStatus,
      PricingCodesCategorySubCategory.pamType,
      PricingCodesCategorySubCategory.salesRegion,
      PricingCodesCategorySubCategory.security,
      PricingCodesCategorySubCategory.salesProgram,
      PricingCodesCategorySubCategory.nmfcClass,
      PricingCodesCategorySubCategory.discType,
      PricingCodesCategorySubCategory.amcType,
      PricingCodesCategorySubCategory.fscTariff,
      PricingCodesCategorySubCategory.rulesTariff,
      PricingCodesCategorySubCategory.packaging,
      PricingCodesCategorySubCategory.plType,
      PricingCodesCategorySubCategory.obiProgram,
      PricingCodesCategorySubCategory.laneDiscountTypes,
    ];
    return this.pricingApi.listPricingCodesByType(queryParams, { loadingOverlayEnabled: false }).pipe(
      map((response: ListPricingCodesByTypeResp) => {
        return response.codeDetails;
      })
    );
  }

  getPricingCodesBasedOnSearch(queryParams: ListPricingCodesByTypeQuery): Observable<Code[]> {
    return this.pricingApi.listPricingCodesByType(queryParams).pipe(
      map((response: ListPricingCodesByTypeResp) => {
        return response.codeDetails;
      })
    );
  }

  getPricingCodes() {
    if (!this.pricingCodesCache$) {
      this.pricingCodesCache$ = this.requestPricingCodes().pipe(shareReplay());
    }
    return this.pricingCodesCache$;
  }

  requestGlobalAccessorials(): Observable<AccessorialService[]> {
    const queryParams = new ListGlobalAccessorialsQuery();
    return this.pricingApi.listGlobalAccessorials(queryParams).pipe(
      map((response: ListGlobalAccessorialsResp) => {
        return response.globalAccessorials;
      })
    );
  }

  getAccessorials() {
    if (!this.globalAccessorialsCache$) {
      this.globalAccessorialsCache$ = this.requestGlobalAccessorials().pipe(shareReplay());
    }
    return this.globalAccessorialsCache$;
  }

  getPricingAnalysisWorkFlow(
    queryParams: ListPricingAnalysisWorkflowProgressQuery,
    pathParams: ListPricingAnalysisWorkflowProgressPath
  ): Observable<PricingAnalysisFlow[]> {
    return this.pricingApi.listPricingAnalysisWorkflowProgress(pathParams, queryParams).pipe(
      map((resp: ListPricingAnalysisWorkflowProgressResp) => {
        return resp.workflowsByProposalId;
      })
    );
  }

  getPricingProposals(queryParams: ListPricingProposalHeadersQuery): Observable<PricingProposalHdr[]> {
    return this.pricingApi.listPricingProposalHeaders(queryParams).pipe(
      map((resp: ListPricingProposalHeadersResp) => {
        return resp.pricingProposalHeaders;
      })
    );
  }

  getManagersDropDownPam(empId: string): Observable<PricingAdminPersonnel[]> {
    const queryParams = new ListPricingAnalystsQuery();
    queryParams.managerEmplId = empId;
    queryParams.levelOfDetail = this.constants.PAM;
    return this.pricingApi.listPricingAnalysts(queryParams).pipe(
      map((resp: ListPricingAnalystsResp) => {
        return resp.pricingAnalysts;
      })
    );
  }

  getManagersDropDownRfp(): Observable<PricingAnalystAssignment[]> {
    const queryParams = new ListPricingAnalystAssignmentsQuery();
    queryParams.levelOfDetail = this.constants.SAM;
    return this.pricingApi.listPricingAnalystAssignments(queryParams).pipe(
      map((resp: ListPricingAnalystAssignmentsResp) => {
        return resp.pricingAnalystAssignments;
      })
    );
  }

  getRunDatePams(): Observable<Date[]> {
    const queryParams = new ListPraAgreementMonitorRunDateQuery();
    return this.pricingApi.listPraAgreementMonitorRunDate(queryParams).pipe(
      map((resp: ListPricingAgreementMonitorRunDateResp) => {
        return resp.pamRunDate;
      })
    );
  }

  getPAMList(queryParams: ListPricingAgreementMonitorsQuery): Observable<PricingAgreementMonitor[]> {
    return this.pricingApi.listPricingAgreementMonitors(queryParams).pipe(
      map((resp: ListPricingAgreementMonitorResp) => {
        return resp.paMonitors;
      })
    );
  }

  getPamDetails(monitorId: string): Observable<GetPricingAgreementMonitorResp> {
    const pathParams = new GetPricingAgreementMonitorPath();
    pathParams.pricingAgreementMonitorId = monitorId;
    return this.pricingApi.getPricingAgreementMonitor(pathParams);
  }

  getPamParametersList(): Observable<PricingAgreementMonitorParameter[]> {
    const queryParams = new ListPricingAgreementMonitorParametersQuery();
    return this.pricingApi.listPricingAgreementMonitorParameters(queryParams).pipe(
      map((resp: ListPricingAgreementMonitorParameterResp) => {
        return resp.paMonitorParameters;
      })
    );
  }

  getCustomerDetails(madCds, instIds): Observable<AccountDetail[]> {
    const queryParams = new ListPricingCustomerDetailsQuery();
    queryParams.acctCd = madCds;
    queryParams.acctId = instIds;
    return this.pricingApi.listPricingCustomerDetails(queryParams).pipe(
      map((resp: ListPricingCustomerDetailsResp) => {
        return resp.custDetails;
      })
    );
  }

  getActivePricingAgreements(acctId: string): Observable<PricingAgreement[]> {
    const queryParams = new ListPricingAgreementsQuery();
    queryParams.acctInstId = acctId;
    return this.pricingApi.listPricingAgreements(queryParams).pipe(
      map((resp: ListPricingAgreementsResp) => {
        return _.each(resp.pricingAgreement, (agreement) => {
          const expiryDate = new Date(agreement.expiryDate);
          console.log(expiryDate);
          if (expiryDate > new Date()) {
            return agreement;
          }
        });
      })
    );
  }

  getCustomersListBasedOnSearch(custAcctCd: string): Observable<ListPricingAgreementDocResp> {
    const queryParams = new ListPricingAgreementDocHdrsQuery();
    queryParams.custAcctCd = custAcctCd;
    return this.pricingApi.listPricingAgreementDocHdrs(queryParams);
  }

  getLiabilityAgreementList(): Observable<PricingAgreement[]> {
    const queryParams = new ListPricingAgreementsQuery();
    queryParams.levelOfDetail = this.constants.LIABILITY;
    return this.pricingApi.listPricingAgreements(queryParams).pipe(
      map((response: ListPricingAgreementsResp) => {
        return response.pricingAgreement;
      })
    );
  }

  getCancelAgreements(queryParams: ListSalesRFPsQuery): Observable<SalesRfp[]> {
    queryParams.levelOfDetail = this.constants.CANCEL_LETTER;
    return this.pricingApi.listSalesRFPs(queryParams).pipe(
      map((response: ListSalesRfpResp) => {
        return response.salesRfp;
      })
    );
  }

  createPamParameter(
    request: CreatePricingAgreementMonitorParameterRqst
  ): Observable<CreatePricingAgreementMonitorParameterResp> {
    return this.pricingApi.createPricingAgreementMonitorParameter(request);
  }

  createPricingCode(request: CreatePricingCodeRqst): Observable<CreatePricingCodeResp> {
    return this.pricingApi.createPricingCode(request);
  }

  createCancelLetterDocument(request, dataOptions: DataOptions): Observable<any> {
    const httpOptions: HttpOptions = {
      responseType: 'blob',
    };
    return this.pricingApi.printCancelPricingAgreementLetter(request, dataOptions, httpOptions);
  }

  uploadRfpAttachments(files: any, rfpId: string): Observable<CreateSalesRfpSupportingDocsResp> {
    const queryParams = new CreateSalesRfpSupportingDocsQuery(),
      dataOptions: DataOptions = {
        loadingOverlayEnabled: true,
        loadingOverlayMessage: 'Uploading...',
        toastOnError: true,
      },
      formData: FormData = new FormData();
    files.forEach((file) => {
      formData.append('docFiles', file);
    });
    const request = new CreateSalesRfpSupportingDocsRqst();
    request.salesRfpId = rfpId;
    request.docFiles = files;
    return this.pricingApi.createSalesRfpSupportingDocs(request, queryParams, dataOptions);
  }

  getSingleDocument(docHdrId: number, docTypeId?: number, oracleDocId?: number): Observable<any> {
    const request = new GetPricingAgreementDocumentPath(),
      queryParams = new GetPricingAgreementDocumentQuery();
    request.agreementDocHdrId = docHdrId;
    queryParams.docTypeId = docTypeId;
    queryParams.oracleDocId = oracleDocId;
    const dataOptions: DataOptions = {
      loadingOverlayEnabled: true,
      loadingOverlayMessage: 'Downloading',
    };
    return this.pricingApi.getPricingAgreementDocument(request, queryParams, dataOptions);
  }

  /*updatePricingCodes(pricingCodeId: string, request: UpdatePricingCodeRqst) {
    const pathParams = new UpdatePricingCodePath();
    pathParams.pricingCodeId = pricingCodeId;
    return this.pricingApi.updatePricingCode(request);
  }*/

  updateRfpDetails(request: UpdateSalesRfpRqst, queryParams: UpdateSalesRfpQuery) {
    return this.pricingApi.updateSalesRfp(request, queryParams);
  }

  updateCancelLetterContactInfo(salesRfpId: string, data: any): Observable<void> {
    const request = new UpdateSalesRfpRqst();
    const queryParams = new UpdateSalesRfpQuery();
    const dataOptions: DataOptions = {
      loadingOverlayEnabled: true,
      loadingOverlayMessage: 'Saving...',
    };
    request.salesRfp = new SalesRfp();
    request.salesRfp.salesRfpHdr = new SalesRfpHdr();
    request.salesRfp.salesRfpContact = new SalesRfpContact();
    request.salesRfp.salesRfpHdr.salesRfpId = salesRfpId;
    request.salesRfp.salesRfpContact = data;
    return this.pricingApi.updateSalesRfp(request, queryParams, dataOptions);
  }

  deletePricingCodes(): Observable<any> {
    const pathParams = new DeletePricingCodePath();
    return this.pricingApi.deletePricingCode(pathParams);
  }
}
