<xpo-shell>
  <xpo-header-app-name>Edge</xpo-header-app-name>
  <xpo-header-sub-app-name>{{ title }}</xpo-header-sub-app-name>
  <xpo-header-navigation>
    <button routerLink="rfp-dashboard" routerLinkActive="xpo-selected">RFP</button>
    <button routerLink="customers" routerLinkActive="xpo-selected">Customers</button>
    <button routerLink="pam-list" routerLinkActive="xpo-selected">PAM</button>
    <button routerLink="pam-parameters" routerLinkActive="xpo-selected">PAM Parameters</button>
    <button (click)="openRating()" routerLinkActive="xpo-selected">What If</button>
  </xpo-header-navigation>
  <xpo-header-utilities-actions>
    <mat-icon>search</mat-icon>
    <xpo-notification-popover [notifications]="notifications"></xpo-notification-popover>
    <xpo-auth-user-profile>
      <xpo-auth-user-profile-links>
        <a [href]="AccountUrls.myAccount" target="_blank">My Account</a>
      </xpo-auth-user-profile-links>
    </xpo-auth-user-profile>
  </xpo-header-utilities-actions>
  <xpo-shell-sidebar-actions class="app-Container-sidebar-actions">
    <div>
      <a (click)="handleReleaseNotesClick()">Release Notes</a>
    </div>
  </xpo-shell-sidebar-actions>
  <router-outlet></router-outlet>
</xpo-shell>
<app-notification></app-notification>
